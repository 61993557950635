/** Wrapper Styles **/

body {

}


h1, h2, h3, h4, h5 {
  font-weight: 300;
}

h2 {
  font-style: italic;
}
h3 {
  font-family: "Times New Roman", cursive;
  font-style: italic;
}

h1 + h2 {
  font-size: rem-calc(35px);
  margin-top: -1rem;
}

a, i {
  transition: all 200ms linear;
}

div,
img {
  outline: none !important;
}

.main p {
  font-size: rem-calc(18px);
}

.wrapper {
	overflow: hidden;
  border-top: 50px solid $primary-color;
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
	float:left;
}

.row {
	clear: both;
}

.section {
	padding: 50px 0;
}

.clear {
  clear: both;
}

.bold {
  font-weight: bold;
}

.blau {
  color: $primary-color;
}

.red-italic,
.blue-italic {
  color: $primary-color;
  font-family: "Times New Roman", cursive;
  font-style: italic;
}
.red-italic {
  color: #cc1331;
}

.bg {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  &.fixed {
    background-attachment: fixed;
  }
}

.gray-background {
  background-color: #f5f5f5;
}
.blue-background {
  background-color: #eef7f9;
}
.white-background {
  background-color: $white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.italic {
  font-style: italic;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding {
	padding: 0 !important;
}