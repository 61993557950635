.header {
  background-color: $white;
  padding-top: 50px;

  .logo-sticky-wrapper {
    display: none;
    position: absolute;
    top: 17px;
    z-index: 9;

    .logo-sticky {
      width: 50%;
      display: block;
    }
  }

  &.is-stuck {
    padding-top: 0;

    .logo-wrapper {
      display: none;
    }

    .phone {
      img {
        width: 40px;
      }
    }

    .logo-sticky-wrapper {
      display: block;
    }

    .main-menu-wrapper {
      border-top: 0 none;
      margin-top: 0;
      padding: 1.5rem 0;

      .menu.align-center {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }
    }
  }
}

.logo-wrapper {
  .mail,
  .kw {
    img {
      transition: all 200ms linear;
      opacity: .8;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  .mail {
    margin-right: 0.5rem;
  }
}

.phone {
  color: $black;
  font-size: rem-calc(20px);
  font-weight: 400;

  img {
    width: 50px;
    margin-right: 0.5rem;
  }
}