
@include breakpoint(1024px down) {
  .design-1 h1 {
    font-size: rem-calc(55px);
  }

  .info-btn {
    font-size: rem-calc(19px);
  }
}

@include breakpoint(medium down) {
  .sticky.is-anchored.is-at-bottom {
    position: static !important;
  }

  [type='checkbox'] + label, [type='radio'] + label {
    vertical-align: top;
    width: 80%;
    margin-top: -3px;
  }
}

@include breakpoint(small down) {
  h1 + h2 {
    font-size: rem-calc(25px);
  }

  .openingtime h1,
  h1, .h1 {
    font-size: 3rem;
  }

  .openingtime h1 {
    text-align: center;
    margin-bottom: 3rem;
  }

  #oeffnungszeiten {
    background-image: none !important;
  }

  .wrapper {
    border-top: 0 none;
  }

  .header {
    padding-bottom: 50px;
    border-top: 50px solid #4382c1;
  }

  .logo-wrapper {
    .text-right {
      text-align: center;
    }

    .logo {
      text-align: center;
      margin-bottom: 1rem;

      img {
        width: 80%;
      }
    }
  }

  .hero-image {
    padding: 6rem 0;

    .hero-text {
      text-align: center;

      h1 {
        font-size: rem-calc(55px);
      }

      p {
        font-size: rem-calc(19px);
      }
    }
  }

  .white-background {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .dat {
    padding-right: 1.8rem !important;
  }

  #return-to-top {
    display: none !important;
  }
}
