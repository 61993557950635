.info-btn {
  background-color: rgba($white, .65);
  padding: 20px 15px;
  text-align: left;
  font-size: rem-calc(25px);
  font-style: normal;
  font-weight: 400;
  color: $black;
  position: relative;

  &:after {
    content: '';
    width: 65px;
    background: $primary-color url(../img/icon-phone-white.png) no-repeat center center;
    background-size: 40% !important;
    display: block;
    height: 103%;
    position: absolute;
    top: -1px;
    right: -1px;
  }

  &:hover {
    i {
      color: $white;
    }
  }

  &.clock {
    &:after {
      background: $primary-color url(../img/icon-clock-white.png) no-repeat center center;
    }
  }
  &.drop {
    &:after {
      background: $primary-color url(../img/icon-drop.png) no-repeat center center;
      background-size: 25% !important;
    }
  }
  &.people {
    &:after {
      background: $primary-color url(../img/icon-people.png) no-repeat center center;
    }
  }
  &.marker {
    &:after {
      background: $primary-color url(../img/icon-marker.png) no-repeat center center;
      background-size: 25% !important;
    }
  }
}

.submit-btn {
  text-transform: uppercase;
}

#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: $primary-color;
  background: rgba($primary-color, 0.7);
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#return-to-top i {
  font-family: "Times New Roman";
  font-style: normal;
  color: #fff;
  margin: 0;
  position: relative;
  left: 14px;
  top: -6px;
  font-size: 51px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#return-to-top:hover {
  background: rgba($primary-color, 0.9);
}
#return-to-top:hover i {
  top: -11px;
}