.openingtime {

  h1 {
    color: $primary-color;
    font-size: rem-calc(65px);
    margin-bottom: 6rem;
  }

  .head {
    text-align: left;
    font-weight: 700;
    font-size: rem-calc(21px);
  }

  td {
    text-align: center;

    span {
      background-color: $primary-color;
      color: $white;
      display: block;
      padding: 5px 0;
    }

    &:hover {
      span {
        background-color: lighten($primary-color, 5%);
      }
    }
  }
}


.hero-image {
  color: $white;
  font-size: rem-calc(30px);
  font-style: italic;
  font-weight: 300;
  padding: 11rem 0;

  h1 {
    font-size: rem-calc(80px);
  }

  .hero-text {
    margin-bottom: 2rem;
  }
}

.design-1 {
  padding: 5rem 0;

  h1 {
    color: $white;
    margin-bottom: 5rem;
    font-weight: 300;
  }
}

.design-2 {
  padding: 3.5rem 0;
}

.popup-gallery {
  .cell {
    padding-bottom: 2rem;
  }
}