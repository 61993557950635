.main-menu-wrapper {
  border-top: 3px solid #e3e3e3;
  margin-top: 30px;
}

.menu-icon::before {
  content: 'Menu';
  left: -50px;
  top: 1px;
  position: relative;
  display: inline-block;
  color: white;
  text-transform: uppercase;
}

.footer-menu,
.main-menu {

  .menu {
    li {
      a {
        padding: 1.5rem 1.8rem;
        text-transform: uppercase;
        font-size: rem-calc(20px);
        font-weight: 400;
        color: $black;


        &.active,
        &:hover {
          color: $primary-color;
        }
      }

      &:first-child {
        a {
        }
      }

      &:last-child {
        a {
          padding-right: 0;
        }
      }

      &.is-active {
        > a {

        }
      }
    }
  }

  .is-dropdown-submenu-parent {
    position: static;
  }

  .is-dropdown-submenu.menu {
    width: 100%;

    li {
      text-align: left !important;
      a {
        color: $black;
      }
    }
  }
}

.mobil-menu {
  position: absolute;
  top: -55px;
  z-index: 99;
  right: 0;
}

// Off-Canvas

.off-canvas-btn {
  padding: 1rem;
  text-align: right;
}


.off-canvas {
  padding-top: 3.5rem;

  li {
    display: block;
    width: 100%;

    a {
      border-right: 0 none;
      border-bottom: 0 none;
      padding: 1rem 0.8rem;
      text-transform: uppercase;
      color: $white;


      &.active,
      &:hover {
        background-color: transparent;
        border-bottom: 0 none;
      }
    }

    .menu {
      padding-left: 1rem;
    }
  }
}